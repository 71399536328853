import * as React from "react";
import { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import IconPlusMinus from "../../images/icon-plus-minus.png";
import IconRxBottle from "../../images/icon-rx-bottle.png";
import IconPrevent from "../../images/icon-prevent.png";
import IconSustain from "../../images/icon-sustain.png";
import BackToTop from "../global/BackToTop.js";
import References from "../global/references.js";
import Modal from "react-bootstrap/Modal";
import $ from "jquery";

class Content extends Component {
   constructor() {
      super();
      this.state = {
         showModal: false,
      };
   }
   closeModal = () => {
      this.setState({ showModal: false });
   };
   showModal = () => {
      if ($(window).width() <= 767) {
         this.setState({ showModal: true });
      }
   };
   render() {
      return (
         <div className={`${this.props.show ? "d-block" : "d-none"}`}>
            <Modal
               centered
               show={this.state.showModal}
               onHide={this.closeModal}
               className="svg-modal"
            >
               <Modal.Body className="px-0">
                  <img
                     src={"/phase-1-target-jurisdictions.svg"}
                     alt="Map of the US Showing Phase 1 Target Jurisdictions"
                  />
               </Modal.Body>
            </Modal>
            <Container
               fluid
               className="hero hiv-rapid-start"
               role="img"
               aria-label="Letterbox view of a
                      person's face"
            />
            <Container className="hiv-rapid-start">
               <Row>
                  <Col xs={10} className="m-auto px-0">
                     <div className="hero-callout muted-yellow-dark">
                        <h1 className="sub-title ">
                           Rapid Start Initiation
                        </h1>
                        <h1 className="title ">
                           Starting HIV Treatment As Soon As Possible
                        </h1>
                     </div>
                     <Container>
                        <Row>
                           <Col xs={12} className="px-0 pr-2">
                              <p>
                              Rapid Start initiation is an HIV treatment strategy that is supported and recommended by many organizations, including the US Department of Health and Human Services (DHHS).<sup>1-4</sup> Rapid Start can provide health benefits for people with HIV, such as an increase in the uptake of ART and linkage to care, decrease in the time to viral suppression, and improvement in viral suppression rates, when HIV treatment is taken as prescribed.<sup>1</sup>
                              </p>
                           </Col>
                           <Col xs={12} className="px-0">
                              <div className="mb-4 mb-lg-5">
                                 <h2 className="title">
                                    What is Rapid Start?
                                 </h2>
                                 <p>
                                    Rapid Start is the initiation of antiretroviral treatment (ART) as soon as possible after an HIV diagnosis.<sup>1</sup> While Rapid Start is supported and recommended by DHHS, the World Health Organization (WHO), the AIDS Education Training Center (AETC), and the New York State Department of Health (NYSDOH), the definitions of Rapid Start and the length of time between diagnosis and treatment initiation vary.<sup>1-4</sup> When initiating ART, it is important to educate patients about the goals and benefits of ART and to identify and address barriers
                                    to care engagement and treatment adherence.
                                 </p>

                                 <Container className="definitions-rapid-start my-3 my-lg-4">
                                    <Row className="flex-lg-nowrap">
                                       <Col xs={12} lg={3} className="px-4 py-3 mb-3 mb-lg-0 text-center d-flex flex-column justify-content-between mr-2 custom-col">
                                          <p>Initiating ART <span class="font-red font-heavy">immediately (or as soon as possible)</span> after an HIV&nbsp;diagnosis<sup>1</sup></p>
                                          <p className="mb-0 text-uppercase font-red font-heavy">DHHS</p>
                                       </Col>
                                       <Col xs={12} lg={3} className="px-4 py-3 mb-3 mb-lg-0 text-center  d-flex flex-column justify-content-between mr-2 custom-col">
                                          <p>Rapid initiation is defined as <span class="font-red font-heavy">within 7 days</span> from the day of HIV&nbsp;diagnosis<sup>2</sup></p>
                                          <p className="mb-0 text-uppercase font-red font-heavy">WHO</p>
                                       </Col>
                                       <Col xs={12} lg={3} className="px-4 py-3 text-center  d-flex flex-column justify-content-between mr-2 custom-col">
                                          <p>Starting HIV treatment <span class="font-red font-heavy">as soon as possible</span> after the diagnosis of HIV infection, preferably on the first clinic visit (and even on the same day the HIV diagnosis is&nbsp;made)<sup>3</sup></p>
                                          <p className="mb-0 text-uppercase font-red font-heavy">AETC</p>
                                       </Col>
                                       <Col xs={12} lg={3} className="px-4 py-3 text-center  d-flex flex-column justify-content-between custom-col">
                                          <p>Rapid initiation of ART preferably on the same day of diagnosis or <span class="font-red font-heavy">within 72</span> hours—should be offered to all individuals who are candidates for rapid ART&nbsp;initiation<sup>4</sup></p>
                                          <p className="mb-0 text-uppercase font-red font-heavy">NYSDOH</p>
                                       </Col>
                                    </Row>
                                 </Container>

                                 <p>
                                    Based on the recommendations from DHHS, WHO, and NYSDOH, Gilead recommends Rapid Start be considered on the same day of diagnosis, within 3 days, or within 7 days.<sup>1,2,4</sup> Gilead's suggested 1-3-7 framework for Rapid Start may help organizations and clinics implement a Rapid Start protocol and help move patients through the HIV care continuum quickly.
                                 </p>

                                 <div className="chart-bg w-100 mx-0 mb-4 mb-lg-5">
                                    <div
                                       onClick={this.showModal}
                                       className="cont-chart ending"
                                    >
                                       <h3 className="font-weight-bold">
                                          Gilead's suggested 1-3-7 framework for Rapid Start
                                       </h3>
                                       <div className="chart-area">
                                          <div className="cont-img p-3 pl-lg-4 d-flex justify-content-center">
                                             <img
                                                className="w-100 framework-rapid-img"
                                                src={"/RapidStartFramework_July2024.svg"}
                                                alt="Gilead's suggested 1-3-7 framework for Rapid Start"
                                             />
                                          </div>
                                       </div>
                                    </div>
                                    <div className="footnote">
                                       Based on recommendations from DHHS, WHO, and NYSDOH.<sup>1,2,4</sup>
                                    </div>
                                 </div>
                              </div>

                              <div className="mb-4 mb-lg-5">
                                 <h2 className="title seo">
                                    Potential benefits of Rapid start
                                 </h2>
                                 <p>
                                    According to DHHS guidelines, beginning HIV treatment as soon as possible after a diagnosis may provide important health benefits, including helping:
                                 </p>
                                 <ul className="ml-lg-5 pl-lg-5">
                                    <li className="mb-2">
                                       <span class="font-red font-heavy">Increase</span> the <span class="font-red font-heavy">uptake</span> of ART and viral suppression rates when HIV treatment is taken as prescribed<sup>1</sup>
                                    </li>
                                    <li className="mb-2">
                                       <span class="font-red font-heavy">Decrease</span> the <span class="font-red font-heavy">time to viral suppression<sup>1</sup></span>
                                    </li>
                                    <li className="mb-2">
                                       <span class="font-red font-heavy">Reduce transmission</span> to HIV-negative sexual partners if an undetectable viral load (HIV-1 RNA &lt;200 c/mL) is maintained for at least 6 months<sup>1</sup>
                                    </li>
                                    <li>
                                       <span class="font-red font-heavy">Improve retention in care<sup>1</sup></span>
                                    </li>
                                 </ul>

                                 <p className="font-weight-bold">
                                    Why Rapid Start Matters:
                                 </p>
                                 <ul className="ml-lg-5 pl-lg-5">
                                    <li className="mb-2">
                                       <span class="font-red font-heavy">Getting to</span> and <span class="font-red font-heavy">keeping</span> an <span class="font-red font-heavy">undetectable viral load</span> may help <span class="font-red font-heavy">reduce internalized stigma</span><sup>5</sup>
                                    </li>
                                    <li className="mb-2">
                                       Data from 2013 to 2014 (N=86) showed that the <span class="font-red font-heavy">median time to viral suppression was 2.5 months sooner among Rapid Start</span> patients (n=39) compared with those in a non–Rapid Start intervention (<em>P</em>=0.0001)<sup>6</sup>
                                    </li>
                                    <li className="mb-2">
                                       Data from 2016 to 2017 (N=274) showed <span class="font-red font-heavy">that more people were virally suppressed (&lt;100 copies/mL) after 1&nbsp;year</span> when they initiated <span class="font-red font-heavy">same-day Rapid Start vs standard ART care</span> (50.4% vs 34.3%; <em>P</em>=0.007)<sup>7</sup>
                                    </li>
                                    <li className="mb-2">
                                       According to DHHS, <span class="font-red font-heavy">achieving and maintaining an undetectable viral load</span> by taking and adhering to ART as prescribed for at least 6 months <span class="font-red font-heavy">prevents transmission of HIV to sexual partners</span><sup>1</sup>
                                    </li>
                                 </ul>
                              </div>

                              <div className="mb-4 mb-lg-5">
                                 <h2 className="title seo">A Rapid Start Case Study: Valley AIDS Council</h2>
                                 <p>
                                    In 2022, the Valley AIDS Council (VAC)—primary provider of HIV services, education, and testing services in the Harlingen, Texas area and Gilead grant recipient—implemented RAPIDO: <span class="font-red font-heavy">R</span>apid <span class="font-red font-heavy">A</span>RT <span class="font-red font-heavy">P</span>rogram for <span class="font-red font-heavy">I</span>ndividuals to reach un<span class="font-red font-heavy">D</span>etectable <span class="font-red font-heavy">O</span>utcomes.<sup>8,9,10</sup> This Rapid ART initiation program focused on making HIV healthcare, education, and resources more accessible to the Latinx communities served by VAC.
                                 </p>
                                 <p>Many factors contribute to low rates of engagement in HIV care by the Latinx community. These include, but are not limited to, stigma, language barriers, lack of education about HIV, and limited access to care and culturally appropriate services.<sup>11</sup></p>

                                 <div className="gray-box funding pl-lg-4">
                                    <h3 className="title-callout">In patients 13 and older:</h3>
                                    <ul>
                                       <li>
                                          In <span class="font-red font-heavy">2021,</span> the Latinx community represented <span class="font-red font-heavy">18%</span> of the US population, but represented <span class="font-red font-heavy">25%</span> of people reportedly living with HIV<sup>12</sup>
                                       </li>
                                       <li>
                                          In <span class="font-red font-heavy">2022</span>, only <span class="font-red font-heavy">73%</span> of HIV-positive Hispanic/Latino people with diagnosed HIV in the US received HIV care<sup>13</sup>
                                       </li>
                                       <li>
                                          No more than <span class="font-red font-heavy">64%</span> of Hispanic/Latino people with diagnosed HIV in the US were virally suppressed<sup>13</sup>
                                       </li>
                                    </ul>
                                 </div>

                                 <p>In just one year, the program was successful in building trustworthiness in their South Texas community, thanks in good part to their ‘red carpet’ approach concerning patient navigation, which included<sup>10</sup>:</p>

                                 <div className="gray-box funding pl-lg-4">
                                    <ul>
                                       <li>
                                          <span class="font-red font-heavy">Providing HIV education</span> (like U=U and the importance of treatment) <span class="font-red font-heavy">within 2 hours</span> of a preliminary positive HIV test
                                       </li>
                                       <li>
                                          Scheduling a <span class="font-red font-heavy">same-day appointment</span> with a clinician (virtual or in-person) <span class="font-red font-heavy">who provides a 2–week</span> ART starter pack
                                       </li>
                                       <li>
                                          <span class="font-red font-heavy">Coordinating with a case manager</span> who provides <span class="font-red font-heavy">8 weeks</span> of focused services, including follow-up testing
                                       </li>
                                    </ul>
                                 </div>

                                 <div className="chart-bg w-100 mx-0 mb-4">
                                    <div

                                       className="cont-chart ending"
                                    >
                                       <h3 className="mb-0">
                                          Results of RAPIDO program after
                                          1 year<sup>14</sup>
                                       </h3>
                                       <div className="chart-area">
                                          <div className="cont-img p-3 pl-lg-4">
                                             <picture className="cont-img">
                                                <source
                                                   media="(max-width: 991px)"
                                                   srcSet={"/RapidCaseStudy_July2024_Mobile.svg"} />
                                                <img
                                                   src={"/RapidCaseStudy_July2024_Desktop.svg"}
                                                   alt="Results of RAPIDO program after
                                          1 year"
                                                />
                                             </picture>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="footnote">
                                       *January-March.
                                       <sup>&dagger;</sup>Missing May value.
                                       <sup>&Dagger;</sup>January-February.
                                    </div>
                                 </div>
                                 <p>Through RAPIDO, VAC showed that a holistic, patient-centric strategy, combined with appropriate treatment and equity, could help in the reduction of barriers to HIV healthcare and lead to better outcomes for people living with HIV.<sup>10</sup></p>
                              </div>

                              <div className="mb-4 mb-lg-5">
                                 <h2 className="title mt-4 pt-3">
                                    Rapid Start and Health Equity
                                 </h2>
                                 <p>
                                    For people living with HIV who face barriers to care, such as income inequality, medical mistrust, or stigma, it can be difficult to seek and receive the care they need.<sup>11,15-17</sup>
                                 </p>
                                 <p>
                                    Rapid Start looks to address bias by standardizing protocols for every patient, no matter their financial situation.<sup>18,19,§</sup> It may also help individuals get linked to care in the same visit, alleviating the burden of coordinating additional visits or taking additional time to return for subsequent appointments.<sup>5,20</sup>
                                 </p>
                                 <div className="gray-box quote-container mx-auto p-4 p-lg-5">
                                    <blockquote className="mb-0" cite="">
                                       <p className="font-red font-medium">“<span class="font-red font-heavy">The Rapid Start program really helps with bias and equity</span> because it is the same protocol [for everyone], and it is not dependent on somebody’s housing status or funding.”<sup>18</sup></p>
                                       <img
                                          className="mx-auto d-block"
                                          src={"/Katherine-Connor-Signature.svg"}
                                          alt="Katherine Connor Signature"
                                       />
                                       <footer className="pt-0">—Director of HIV Services, CrescentCare Start Initiative, on their Rapid Start Program</footer>
                                    </blockquote>
                                 </div>
                                 <p>
                                    Linking patients to treatment immediately following a positive HIV diagnosis may lead to better engagement in care and, therefore, better health outcomes in the long term.<sup>15,16</sup>
                                 </p>
                                 <div className="footnote">
                                    <sup>§</sup>As noted in a CrescentCare Biennial Report about the Rapid Start program, CCSI (CrescentCare Start Initiative).<sup>18,19</sup>
                                 </div>
                              </div>


                           </Col>
                           <BackToTop />
                        </Row>
                     </Container>
                  </Col>
               </Row>
            </Container>
            <References page={this.props.page} />
         </div>
      );
   }
}

export default Content;
